import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
var data = []

const ChartRealTime = (props) => {
  
  const chartState = {
          
    series: [{
      name: "Puissance électrique (KW)",
      data: data.slice()
    }],
    options: {
      chart: {
        id: 'realtime',
        height: 350,
        type: 'area',
        animations: {
          enabled: true,
          easing: 'linear',
          dynamicAnimation: {
            speed: 1000
          }
        },
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      // title: {
      //   text: "Aujourd'hui",
      //   align: 'left'
      // },
      markers: {
        size: 0
      },
      xaxis: {
        type: 'datetime',
        
        range: 500000,
        labels: {
          // format: 'dd/MM',
        }
      },
      tooltip: {
        x: {
          format: 'dd MMM HH:mm:ss'
        }
      },
      
      legend: {
        show: false
      },
      stroke: {
        curve: 'smooth'
      },
      
      fill: {
        type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
      }
    },
  
  
  };



  useEffect(() => {
    // realtime()
    console.log('RealTime_mounted');
    console.log(props.Data);
    ApexCharts.exec('realtime', 'updateSeries', [{
      data: props.Data
    }])

  }, [props.Data]);
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div className="flex flex-wrap items-center">
        <div className="relative w-full max-w-full flex-grow flex-1">
          
          <h3 className="text  font-semibold">Puissance Electrique (KW)</h3>
        </div>
      </div>
    </div>
    <div className="p-4 flex-auto">
      {/* Chart */}
        <div className="relative h-full">
          <div id="chart">
            <Chart
                options={chartState.options}
                series={chartState.series}
                type="area"
                height="230"
            />
          </div>
        </div>
     </div>
    </div>
  );
};

export default ChartRealTime;