import React, { useEffect, createContext } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../../UserPool";

const AccountContext = createContext();
// holla
const Account = (props) => {  

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject("noSession");
          } else {

            const token = session.getIdToken().getJwtToken()
            resolve({
              headers: {
                Authorization: token
              }
            })
          }
        });
      } else {

        reject("noUser");
      }
    });
  };

  const getAttributes = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject("Error noSession");
          } else 
          {
            const token = session.getIdToken().getJwtToken()
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                }
              });
            });

            resolve({
              Authorization: token,
              ...attributes
            });
          }
        });
      } else {
        reject("noUser");
      }
    });



  const authenticate = async (Username, Password) => {
    // console.log(Pool)  
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("onSuccess: ", data);
          resolve(data);
        },
        onFailure: (err) => {
          console.error("onFailure: ", err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          console.log("newPasswordRequired: ", data);
          resolve({code: 1, data});
        },
      });
    });
  };

  const authAndChangePassw = async (Username, Password, newPassword) => {
    // console.log(Pool)  
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("onSuccess: ", data);
          resolve(data);
        },
        onFailure: (err) => {
          console.error("onFailure: ", err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          console.log("Change PasswordRequired: ", data, newPassword);
          delete data.email_verified;
          user.completeNewPasswordChallenge(newPassword, data, {
            onSuccess: result => {
                resolve(result)
            },
            onFailure: err => {
                reject(err);
            }
        });
          // resolve(data);
        },
      });
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      console.log('log Out !!!!!!!!!');
      user.signOut();
      // localStorage.clear();
      localStorage.removeItem('idClient');
      localStorage.removeItem('Authorization');
 
    }
  };

  useEffect(() => {
    console.log(1);
  }, [])
  return (
    <AccountContext.Provider value={{ authenticate, getSession, getAttributes , logout, authAndChangePassw }}>
      {props.children}
    </AccountContext.Provider>
  );
};
export { Account, AccountContext };