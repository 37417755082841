import React, { useState, useContext } from "react";
import { AccountContext } from "../../services/auth/Account.js";
// import { Link } from "react-router-dom";
import Alert from "services/alerts/Alerts.js";
import { ClapSpinner  } from "react-spinners-kit"
import UserData from '../../services/user/userData'

export default function Login({ history }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorLogin, setErrorLogin] = useState(false);
  const [firstLogin, setFirstLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  

  const { authenticate, authAndChangePassw, getAttributes } = useContext(AccountContext);

  const onSubmit = (event) => {
    event.preventDefault();

    setLoading(true)
    authenticate(email, password)
      .then((data) => {
        setLoading(false)
        console.log("Logged in!", data);
        setErrorLogin(false)
        if(data.code) {
          setFirstLogin(true)
        }
        else {
          
          getAttributes()
          .then((result) => {
            console.log(result);
            localStorage.setItem('idClient', result['custom:idClient'])
            localStorage.setItem('Authorization', result['Authorization'])

            if(localStorage.getItem('nrcSelectedStationID') == null){

              UserData.getUserData({
                ID: localStorage.getItem('idClient'),
              })
              .then(res => {
                console.log(res.data)
                if(res.data.data && res.data.data.stations)
                {
                  console.log(res.data.data.stations)
                  localStorage.setItem('nrcSelectedStationID', res.data.data.stations[0].id)
                  localStorage.setItem('nrcSelectedStationName', res.data.data.stations[0].nomeStation)

                }
                else{
                  localStorage.setItem('nrcSelectedStationID', localStorage.getItem('idClient'))
                }

                history.replace('/admin');

              })
              .catch(error => {
                console.error("errr"+error)
                alert(JSON.stringify(error))
              })
            
            }else{
              history.replace('/admin');
            }

            
            
          })
          .catch(err => {
            console.log(err)
            setErrorLogin(true)
            setErrorMsg("Error attributes not exist !!")
          });
          
        }
      })
      .catch((err) => {
        setLoading(false)
        console.error("Failed to login", err);
        setErrorLogin(true)
        setErrorMsg(err.message)
      });
  };

  const onSubmitChangePassw = (event) => {
    event.preventDefault();

    if( newPassword1 === newPassword2)
    {
     console.log("submit Change --------------------");

      authAndChangePassw(email, password, newPassword1)
      .then((data) => {
        console.log("Logged in!", data);
        setErrorLogin(false)
        history.replace('/admin')
      })
      .catch((err) => {
        console.error("Failed to login", err);
        setErrorMsg(err.message)
        setErrorLogin(true)
      });
    }
    else  {
    console.log("subm err------------------");
      setErrorLogin(true)
      setErrorMsg("Passwords not identique")
    }
  }


  return (
     
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            {errorLogin && <Alert Text={errorMsg} /> }
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">
                    Sign in 
                    <div className="flex items-center w-full">
               <ClapSpinner  size={30} color="#686769" loading={loading} />
              </div>
                  </h6>
                </div>
                
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              
                
                { firstLogin ? 
                <form onSubmit={onSubmitChangePassw}> 
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                    value={newPassword1}
                    autoComplete="off"
                     onChange={(event) => setNewPassword1(event.target.value)}
                  />
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Password"
                    value={newPassword2}
                    autoComplete="off"
                     onChange={(event) => setNewPassword2(event.target.value)}
                  />
                </div>
                

                <div className="text-center mt-6">
                  <button
                    className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Sign In
                  </button>
                </div>
              </form>
              : <form onSubmit={onSubmit}> 
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Username
                </label>
                <input
                  type="text"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Username"
                  value={email}
                   onChange={(event) => setEmail(event.target.value)}
                />
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                  value={password}
                   onChange={(event) => setPassword(event.target.value)}
                />
              </div>
              <div>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span className="ml-2 text-sm font-semibold text-blueGray-600">
                    Remember me
                  </span>
                </label>
              </div>

              <div className="text-center mt-6">
                <button
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Sign In
                </button>
              </div>
            </form>}
                
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a>
              </div>
              {/* <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div> 
  
  );
}
