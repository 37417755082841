import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";

const ChartPuissance = (props) => {

  const updateChart = () => {
      ApexCharts.exec('puissance', 'updateSeries', [{
        data: props.Data
      }])
  };



  const chartState = {

    options: {
      chart: {
        id: "puissance",
        width: "100%",
        type: "area",
        animations: {
          enabled: false,
          initialAnimation: {
            enabled: false
          }
        }
      },
      xaxis: {
        type: 'datetime'
      },
      // yaxis: {
      //   labels: {

      //     formatter: function(val) {
      //       return val.toFixed(8);
      //     }
      //   }
      // },
      stroke: {
        curve: 'smooth',
      },
      markers: {
        size: 0
        // style: 'hollow',
      },
      // fill: {
      //   type: 'gradient' ,// 'pattern' / 'image'
      //   gradient: {
      //     shadeIntensity: 1,
      //     opacityFrom: 0.7,
      //     opacityTo: 0.9,
      //     stops: [0, 100]
      //   }
      // },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        x: {
          format: 'dd-MM-yyyy HH:mm:ss'
        }
      },
    },
    series: [
      {
        name: "Puissance électrique (KW)",
        data: [
          // [1486684800000, 34], 
          // [1486771200000, 43], 
          // [1486857600000, 31] , 
          // [1486944000000, 43], 
          // [1487030400000, 33], 
          // [1487126800119, 52]
        ]
      }
    ]
    
  };




  useEffect(() => {
    //setTimeout(() => {
      
      updateChart();
      console.log(props.Data)
    //}, 100);
  }, [props.Data]);
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div className="flex flex-wrap items-center">
        <div className="relative w-full max-w-full flex-grow flex-1">
          <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
          
          </h6>
          <h2 className="text text-xl font-semibold">Puissance Electrique (KW)</h2>
        </div>
      </div>
    </div>
    <div className="p-4 flex-auto">
      {/* Chart */}
      <div className="relative h-full">
   
     
      <Chart
        options={chartState.options}
        series={chartState.series}
        type="area"
        height="300"
      />
    
           </div>
         </div>
       </div>
  );
};

export default ChartPuissance;