import React , {useState, useEffect, useContext} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { AccountContext } from "../services/auth/Account";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
// import Register from "views/auth/Register.js";

export default function Auth({ history }) {
  const [isAuth, setIsAuth] = useState(true);
  const { getSession } = useContext(AccountContext);

  useEffect(() => {
    getSession()
    .then((session) => {
      console.log('redirect to admin');
      history.replace('/admin');
    })
    .catch(err => {
      setIsAuth(false)
      console.log(err)
    });
   
  }, []);

  // fff
  return !isAuth ? (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/login-bg.jpg").default + ")",
            }}
          ></div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            {/* <Route path="/auth/register" exact component={Register} /> */}
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  ) : null

}
