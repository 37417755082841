import axios from "axios"
const urlUserData = process.env.REACT_APP_GET_USER_DATA


const UserData = {

    getUserData: async ({ID}) => {
        try {

            const response = await axios.get(urlUserData+ID)//,{headers})
            
            return response
        } catch (err) {
            throw err
        }
    },

}

export default UserData