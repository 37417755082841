import React, { useEffect } from "react";
import Chart from "react-apexcharts";


const ChartFreq = (props) => {

  function valueToPercent (value,max) {
    return (value * 100) / max
  } 

  function percentToValue (value,max) {
    return (value * max) / 100
  } 
  

  const chartState2 = {
    series: [valueToPercent(props.Val, props.Max)],
    options : {
        chart: {
        height: 350,
        type: 'radialBar',
        toolbar: {
          show: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
           hollow: {
            margin: 0,
            size: '85%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: '#fff',
            strokeWidth: '67%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },
      
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '17px'
            },
            value: {
              formatter: function(val) {
                return parseFloat(val*parseFloat(props.Max)/100).toFixed(2);
              },
              color: '#111',
              fontSize: '36px',
              show: true,
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          // gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [90, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: [props.Label],
      }
  }


  useEffect(() => {
    // chart();
  }, []);
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div className="flex flex-wrap items-center">
        <div className="relative w-full max-w-full flex-grow flex-1">
          <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
          
          </h6>
        </div>
      </div>
    </div>
    <div className="p-4 flex-auto">
      {/* Chart */}
      <div className="relative h-full">
   
     
      <Chart
        options={chartState2.options}
        series={chartState2.series}
        type="radialBar"
        height="290"
      />
    
           </div>
         </div>
       </div>
  );
};

export default ChartFreq;