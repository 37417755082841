import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
// import axios from "axios";

const ChartEnergie = ({Id, Data, Title, Color1, Color2, childChartEnergyIndex}) => {
  // const [chartData, setChartData] = useState({});
  // const [Labels, setLabels] = useState([]);
  // const [Data, setData] = useState([]);


  const updateChart = () => {

    ApexCharts.exec(Id, 'updateSeries', 
    [
      {
        data: Data[0]
      }
      // ,
      // {childToParent(data
      //   data: props.Data[1]
      // }
    ])
  };

  const chartState = {

    options: {
      chart: {
        id: Id,
        width: "100%",
        type: "bar",
        animations: {
          initialAnimation: {
            enabled: false
          }
        },
        events: {
          // dataPointSelection: (event, chartContext, config) => {
          //   console.log(chartContext, config);
          // },
          click: function(event, chartContext, config) {
            console.log(config);
            childChartEnergyIndex(config.globals.labels[config.dataPointIndex])
          }
        }
      },
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      },
      title: {
        text: Title,
        align: 'left'
      },
      xaxis: {
        type: 'category'
      },
      yaxis: {
        labels: {

          formatter: function(val) {
            return val.toFixed(3);
          }
        }
      },
      stroke: {
        curve: 'smooth',
      },
      markers: {
        size: 0,
        style: 'hollow',
      },
    
      colors: [Color1, Color2],
      
      // fill: {
      //   type: 'gradient' ,// 'pattern' / 'image'
      //   gradient: {
      //     shadeIntensity: 1,
      //     opacityFrom: 0.7,
      //     opacityTo: 0.9,
      //     stops: [0, 100]
      //   }
      // },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return val.toFixed(4);
        }
      },

    
    },
    series: [
      {
        name: "Energie consommée (KWh)",
        data: []
      },
      // {
      //   name: "Facturation ($)",
      //   data: []
      // }
    ]
    
  };

  useEffect(() => {
    updateChart()
    console.log(Data);
  }, [Data]);
  
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div className="flex flex-wrap items-center">
        <div className="relative w-full max-w-full flex-grow flex-1">
          <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
          
          </h6>
          {/* <h2 className="text text-xl font-semibold">Ene (KW)</h2> */}
        </div>
      </div>
    </div>
    <div className="p-4 flex-auto">
      {/* Chart */}
      <div className="relative h-full">
   
     
      <Chart
        options={chartState.options}
        series={chartState.series}
        type="bar"
        height="300"
        onChange={()=> console.log(chartState.series)}
      />
    
           </div>
         </div>
       </div>
  );
};

export default ChartEnergie;