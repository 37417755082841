import React from "react";

// components

//import CardTable from "components/Cards/CardTable.js";
import CardTableElement from "components/Cards/CardTableElement";


export default function Tables() {
  return (
    <>
        <div className="w-full">
          <CardTableElement />
        </div>
    </>
  );
}
