import React   from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import AuthRoute from "services/auth/AuthRoute";




const App = () => {
  
    return (
    <BrowserRouter>
        <Switch>
          <Route path="/admin" component={Admin} />
          <Route path="/auth" component={Auth} />
          <Route path="/landing" exact component={Landing} />
          <AuthRoute path="/profile" exact component={Profile} />
          <AuthRoute path="/" exact component={Auth} />
      </Switch>
    </BrowserRouter>
    );
}
export default App;
