
const DateOperations = {

    LastAquisitionLooksNear:  (timeNow, timeLast) => {
        // console.log('-----' + timeLast)
        // console.log('-----' + parseInt(timeLast))
        // console.log('-----' + timeNow)
        // console.log('-----' + parseInt(timeNow))
        // console.log('--------- '+(parseInt(timeNow) - parseInt(timeLast)));
        if ((parseInt(timeNow) - parseInt(timeLast)) < (60 * 20) )
            return true
        else return false
    }
}

export default DateOperations
