import axios from "axios"
const urlRealTimeData = process.env.REACT_APP_GET_REALTIME_DATA


const RealTimeService = {

    getRealTimeData: async ({phase, id, nbRows, headers}) => {
        try {

            const response = await axios.get(urlRealTimeData+phase+id+'/'+nbRows,{headers})
            
            return response
        } catch (err) {
            throw err
        }
    },

}

export default RealTimeService