import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, Select } from 'antd';
import { ClapSpinner  } from "react-spinners-kit"
import ChartPuissance from "components/Cards/ChartPuissance";
import ChartEnergie from "components/Cards/ChartEnergie";
import DataTimeService  from "../../services/counter/datatimeService"
import DateOperations from "utils/helpers/dateOperations";

const { Option } = Select;

export default function Chart() {
  const [phase, setPhase] = useState("1")
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [chartPuissanceData, setChartPuissanceData] = useState([]);
  const [chartEnergieDay, setChartEnergieDay] = useState([]); 

  const [chartEnergieMonth, setChartEnergieMonth] = useState([]);
  const [chartEnergieYear, setChartEnergieYear] = useState([]);
  const [loading, setLoading] = useState(false);


  const childChartEnergyIndex = (index) =>  {
    let dayFetch = new Date(index)
    if(index){

      setChartPuissanceData([])
      setLoading(true)
      let List_data = [];
      console.log(dayFetch);
      DataTimeService.getDataCounterByTime({
        client: localStorage.getItem('nrcSelectedStationID'), 
        phase: phase, 
        startDate: (dayFetch.toISOString()).substring(0,10)+" 00:00:00", 
        endDate:   (dayFetch.toISOString()).substring(0,10)+" 23:59:59"
      })
      .then(res => {
        for (const dataObj of res.data.Items) {
          List_data.push([
            parseInt(dataObj.time+"000"),
            parseFloat(dataObj.msg.pactive)
          ]);
        }
        setChartPuissanceData(List_data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err);
        alert(JSON.stringify(err))
        setLoading(false)
  
      });     
    }
  } 

  const getData = () => {
    setChartPuissanceData([])
    setChartEnergieDay([[],[]])
    setLoading(true)
    let List_data = [];
    let day_data = [[],[]];
    // let month_data = [[],[]];
    // let year_data = [[],[]];
    let resultEnergy, energyTime;

    if(((startDate.toISOString()).substring(0,10) == ((new Date()).toISOString()).substring(0,10)) ||  
          ((startDate.toISOString()).substring(0,10) == (endDate.toISOString()).substring(0,10)) )
    {
      DataTimeService.getDataCounterByTime({
        client: localStorage.getItem('nrcSelectedStationID'), 
        phase: phase, 
        startDate: (startDate.toISOString()).substring(0,10)+" 00:00:00", 
        endDate:   (endDate.toISOString()).substring(0,10)+" 23:59:59"
      })
      .then(res => {
        console.log(res);

          if(res.data.Items[0]) 
          {
            DataTimeService.getLastAquisition({
            client: localStorage.getItem('nrcSelectedStationID'), 
            phase: phase, 
            startDate: parseInt(res.data.Items[0].time), 
          })
          .then(resLastAquisition => {
            if(DateOperations.LastAquisitionLooksNear(res.data.Items[0].time, resLastAquisition.data[0].time))
              energyTime = parseInt(resLastAquisition.data[0].time)
            else energyTime = parseInt(res.data.Items[0].time) - 3
            // energyTime = parseInt(resLastAquisition.data[0].time)
            console.log(res.data.Items[0].time+ ' | '+ energyTime + ' % '+ parseInt(resLastAquisition.data[0].time) );
            resultEnergy = 0
            for (const dataObj of res.data.Items) {
              resultEnergy = resultEnergy + ( parseFloat(dataObj.msg.pactive)*(parseInt(dataObj.time) - energyTime) / 3600 )
              List_data.push([
                parseInt(dataObj.time+"000"),
                parseFloat(dataObj.msg.pactive)
              ]);
              energyTime = parseInt(dataObj.time)
              
            }
            // console.log(List_data)
            setChartPuissanceData(List_data)
            day_data[0].push({
              x: (startDate.toISOString()).substring(0,10),
              y: resultEnergy
            }) 
            day_data[1].push({
              x: (startDate.toISOString()).substring(0,10),
              y: resultEnergy *1.2
            }) 
            
            console.log( day_data )
            setChartEnergieDay(day_data)
            
            setLoading(false)
          })
          .catch(err => {
            
            alert("error LastAquisition non")
            setLoading(false)
            
          })
        } else {
          alert("Il n'y a aucun élément")
          setLoading(false)

        }
            
      })
      .catch(err => {
        console.log(err);
        alert(JSON.stringify(err))
        setLoading(false)

      });
      
      } else {
        var endateIsToday = false
        var thisEndDate =  (endDate.toISOString()).substring(0,10)+" 23:59:59"
        if((endDate.toISOString()).substring(0,10) == ((new Date()).toISOString()).substring(0,10)){
          endateIsToday = true
          thisEndDate = ((new Date(((Date.parse(thisEndDate)/1000 ) - (3600 * 24)) * 1000)).toISOString()).substring(0,10)+" 23:59:59"
        } 

        DataTimeService.getEnergyByTimeZero({
          client: localStorage.getItem('nrcSelectedStationID'), 
          phase: phase, 
          startDate: (startDate.toISOString()).substring(0,10)+" 00:00:00", 
          endDate:  thisEndDate
        })
        .then(res => {
           console.log(res);
          day_data = [[],[]];
          for (var [cle, valeur] of Object.entries(res.data.energyTable)){
            day_data[0].push({
              x: cle,
              y: valeur
            }) 
            day_data[1].push({
              x: cle,
              y: valeur *1.2
            }) 
          }
          setChartEnergieDay(day_data)

          
          // energyTime = res.data.energyTime
          //console.log("=-=-=-==-= " + energyTime);
          if(endateIsToday){
            DataTimeService.getDataCounterByTime({
              client: localStorage.getItem('nrcSelectedStationID'), 
              phase: phase, 
              startDate: (endDate.toISOString()).substring(0,10)+" 00:00:00", 
              endDate:   (endDate.toISOString()).substring(0,10)+" 23:59:59"
            })
            .then(res2 => {
              setChartEnergieDay([[],[]])
              if(DateOperations.LastAquisitionLooksNear(res2.data.Items[0].time, res.data.energyTime))
                energyTime = res.data.energyTime
              else energyTime = parseInt(res2.data.Items[0].time) - 3
              
              resultEnergy = 0
              for (const dataObj of res2.data.Items) {
                resultEnergy = resultEnergy + parseFloat(dataObj.msg.pactive)*(parseInt(dataObj.time) - energyTime) / 3600
                
                List_data.push([
                  parseInt(dataObj.time+"000"),
                  parseFloat(dataObj.msg.pactive)
                ]);
                // GroupBy([parseInt(dataObj.time+"000"),resultEnergy])
                energyTime = parseInt(dataObj.time)
                
              }
              setChartPuissanceData(List_data)
              
              day_data[0].push({
                x: (endDate.toISOString()).substring(0,10),
                y: resultEnergy
              })
              day_data[1].push({
                x: (endDate.toISOString()).substring(0,10),
                y: resultEnergy *1.2
              }) 
              console.log(resultEnergy); 
              console.log((endDate.toISOString()).substring(0,10));
              setChartEnergieDay(day_data)

              setLoading(false)
              
            }).catch(err => {
              console.log(err);
              alert(JSON.stringify(err))
              setLoading(false)
      
            });
          }else {
            setLoading(false)
          }

        }).catch(err => {
          console.log(err);
          alert(JSON.stringify(err))
          setLoading(false)
  
        });
      }
  }


  const onTimeStartChange = (date, dateString) => {
    setStartDate(date)
  } 

  const onTimeEndChange = (date, dateString) => {
    setEndDate(date)
  }

  const onPhaseChange = value => {
    setPhase(value);
   } 
   
  const onScan = event => {
    getData()
    console.log('get')
  }

  useEffect(() => {
    onScan();
  }, [phase]);

  return (
    <>

    <div className="flex justify-start  mb-3">
        <DatePicker
          defaultValue={moment()}
          format="DD-MM-YYYY"
          onChange={onTimeStartChange}
        />
        <DatePicker
          defaultValue={moment()}
          format="DD-MM-YYYY"
          onChange={onTimeEndChange}
        />
     
        <Select style={{height: "46px"}}  defaultValue="1" onChange={onPhaseChange} style={{ width: 200 }}>
          { ( localStorage.getItem('nrcSelectedStationID') != 9) ?
            (<><Option key="1">Phase 1</Option>
              <Option key="2">Phase 2</Option>
              <Option key="3">Phase 3</Option></>)
            :
            (<><Option key="1">Général</Option>
              <Option key="2">Moteur</Option></>)
          } 
          
        </Select>
      
        { 
          loading ? <div className="p-2" > <ClapSpinner  size={30} color="#0EA5E9" loading={loading} /> </div>
            : 
          <button onClick={() => onScan()} className="text-lightBlue-500 bg-transparent border border-solid border-lightBlue-500 hover:bg-lightBlue-500 hover:text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none">
            Scan
          </button>
        }
    </div>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-12/12">
          <ChartPuissance Data={chartPuissanceData}/>
        </div>
        <div className="w-full xl:w-12/12">
          <ChartEnergie Id="energieday" Color1="#008ffb" Color2="#00e296" Title="Consommation énergétique journalière (KWh)" Data={chartEnergieDay} childChartEnergyIndex={childChartEnergyIndex} />
        </div>
        <div className="w-full xl:w-6/12 pr-3">
          <ChartEnergie Id="energiemonth" Color1="#546E7A" Color2="#E91E63" Title="Consommation énergétique mensuel (KWh)" Data={chartEnergieMonth} />
        </div>
        <div className="w-full xl:w-6/12">
          <ChartEnergie Id="energieyear" Color1="#313b72" Color2="#d62828" Title="Consommation énergétique annuel (KWh)" Data={chartEnergieYear} />
        </div>
      </div>
     
    </>
  );
}
