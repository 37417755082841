import React from "react";
import ReactDOM from "react-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/css/datepicker.css"
import './antd.css';
import App from "./App";
// layouts

// import Admin from "layouts/Admin.js";
// import Auth from "layouts/Auth.js";

// // views without layouts

// import Landing from "views/Landing.js";
// import Profile from "views/Profile.js";
import { Account } from "services/auth/Account";
// import Index from "views/Index.js";

ReactDOM.render(
  <Account>
    <App/>
  </Account>
  ,
  document.getElementById("root")
);  