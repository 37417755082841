import axios from "axios"
const urlEndPointDataByTime = process.env.REACT_APP_GET_DATA_BY_TIME
const urlLastAquisition = process.env.REACT_APP_GET_LAST_AQUISITION
const urlEnergyByTimeZero = process.env.REACT_APP_GET_ENERGY_BY_TIME_ZERO

axios.defaults.timeout = 1500000;


const DataTimeService = {

    getDataCounterByTime: async ({client, phase, startDate, endDate}) => {
        try {
            const response = await axios.post(urlEndPointDataByTime, {
                client: client, 
                phase: phase, 
                startDate: startDate, 
                endDate:  endDate
              })
            return response
        } catch (err) {
            throw err
        }
    },


    getEnergyByTimeZero: async ({client, phase, startDate, endDate}) => {
        try {
            const response = await axios.post(urlEnergyByTimeZero, {
                client: client, 
                phase: phase, 
                startDate: startDate, 
                endDate:  endDate
              })
            return response
        } catch (err) {
            throw err
        }
    },

    getLastAquisition: async ({client, phase, startDate}) => {
        try {
            const response = await axios.post(urlLastAquisition, {
                client: client, 
                phase: phase, 
                startDate: startDate
              })
            return response
        } catch (err) {
            throw err
        }
    },
    
}

export default DataTimeService