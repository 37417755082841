import React from "react";


export default function HeaderStats() {

 
  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-100 pt-2">
        <div className="px-1 md:px-4 mx-auto w-full">
         
        </div>
      </div>
    </>
  );
}
