import React from "react";
// import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useHistory  } from "react-router-dom";


const CardStation = ({  IdStation, NomeStation }) => {

  const history = useHistory();

  const changeStation = (event) => {
   
    localStorage.setItem('nrcSelectedStationID', IdStation)
    localStorage.setItem('nrcSelectedStationName', NomeStation)
    history.push('/admin');
    // navigate("/admin");
  }

  return (
    // <div className="xl:w-4/12 mb-12 xl:mb-0 px-4 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white ">
    // <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
    //   <div className="flex flex-wrap items-center">
    //     <div className="relative w-full max-w-full flex-grow flex-1">
    //       <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
    //             Taza
    //       </h6>
    //     </div>
    //   </div>
    // </div>
    // {/* <div className="p-4 flex-auto">
      
    //   <div className="relative h-full">
   
     
     
    
    //        </div>
    //      </div> */}
    //    </div>
    (

      localStorage.getItem('nrcSelectedStationID') != IdStation ?
      ( <button
          className="bg-transparent border border-solid border-lightBlue-500 hover:bg-lightBlue-500 hover:text-white active:bg-lightBlue-600 text-lightBlue-500 active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          onClick={changeStation}
          >
          {NomeStation}
        </button>   
      ) : 
      (
        
        <button
        className="bg-lightBlue-500 text-white  uppercase text-xs px-4 py-2 rounded shadow  outline-none e mr-1 ease-linear transition-all duration-150"
        >
          {NomeStation}
        </button>
      )
    )   

    
    
 
  );
};

export default CardStation;