import React from "react";
import { Switch } from "react-router-dom";

// components

import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Chart from "views/admin/Chart.js";
import Maps from "views/admin/Maps.js";
import Settings from "views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import AuthRoute from "services/auth/AuthRoute";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
      <HeaderStats />
      
        <div className="px-4 md:px-10 mx-auto w-full m-1">
          <Switch>
            <AuthRoute path="/admin/dashboard" exact component={Dashboard} />
            <AuthRoute path="/admin/chart" exact component={Chart} />
            <AuthRoute path="/admin/maps" exact component={Maps} />
            <AuthRoute path="/admin/settings" exact component={Settings} />
            <AuthRoute path="/admin/tables" exact component={Tables} />
            <AuthRoute path="/admin" exact component={Dashboard} />
            {/* <Redirect from="*" to="/admin/dashboard" />
            <Redirect from="/admin" to="/admin/dashboard" /> */}
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
