

export const columns = [
    {
      title: 'Temps',
      dataIndex: 'Time',
      render: t => new Date(t).toLocaleString("fr-FR"),
      // width: '10%',
      fixed: 'left',
    },
    {
      title: 'Fréquence (Hz)',
      dataIndex: 'Frequency',
      // width: '10%',
    },
    {
      title: 'Tension (V)',
      dataIndex: 'Voltage',
      
    },
    {
      title: 'Courant (A)',
      dataIndex: 'Current',
    },
    {
      title: 'Puissance Active (KW)',
      dataIndex: 'Pactive',
    },
    {
      title: 'Puissance Réactive (KVAR)',
      dataIndex: 'Preactive',
    },
    {
      title: 'Facteur de puissance',
      dataIndex: 'Fp',
    },
    {
      title: 'Energie Active (KWh)',
      dataIndex: 'EnergyActive',
    },
    {
      title: 'Energie Réactive (KVARh)',
      dataIndex: 'EnergyReactive',
    },
];

