import React, { useState, useContext, useEffect } from "react";
import { Redirect, Route } from "react-router";
import { AccountContext } from "./Account";

const AuthRoute = ({path, component}) => {
  const [status, setStatus] = useState(false);
  const [result, setResult] = useState(false);

  const { getSession } = useContext(AccountContext);

  useEffect(() => {
    console.log("check");
    getSession()
    .then((session) => {
      console.log("Session good :", session);
      console.log(path);
      console.log(component);
      setStatus(true);
      setResult(true);
    })
    .catch(err => {
      console.log("session nooo");
      console.log(err)
      setStatus(false)
      setResult(true)
    });
  }, []);

  return result ? ( status ? (
    <Route exact path={path} component={component} />
  ) : (
      <Redirect to="/auth" />
  )) : null
}
export default AuthRoute;
