import React, { useState, useEffect, useRef, useContext } from "react";
import axios from 'axios';
import Select from 'react-select'
// components

import ChartFreq from "components/Cards/ChartFreq";
import ChartRealTime from "components/Cards/ChartRealTime";
import MultiProgressData from "components/Cards/MultiProgessData";

import { AccountContext } from "../../services/auth/Account"
import DataTimeService  from "../../services/counter/datatimeService"
import RealTimeService from "../../services/counter/realtimeService";
import DateOperations from "utils/helpers/dateOperations";



function useInterval(callback, delay) {
  const savedCallback = useRef();
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function Dashboard() {
  const [courant, setCourant] = useState(0)
  const [energyactive, setEnergyactive] = useState(0)
  const [energyreactive, setEnergyreactive] = useState(0)
  const [pactive, setPactive] = useState(0)
  const [preactive, setPreactive] = useState(0)
  const [papparente, setPapparente] = useState(0)
  const [voltage, setVoltage] = useState(0)
  const [frequence, setFrequence] = useState(0) 
  const [fp, setFp] = useState(0)
  const [lastEnergyTime, setLastEnergyTime] = useState(0)
  
  const [phase, setPhase] = useState("1")
  const [realTimeData, setRealTimeData] = useState([])
  var data = []
  const { getSession } = useContext(AccountContext);
  const urlLastAquisition = process.env.REACT_APP_GET_LAST_AQUISITION

  useInterval(() => {
    getSession()
    .then(({headers}) => {
      // console.log(headers)
      getNewData(headers)
    })
    .catch(err => {
      console.log(err)
    });
  }, 3000);

  const getNewData = (headers) => {
    let energy, energyRe
     
    RealTimeService.getRealTimeData({
      phase: phase,
      id: localStorage.getItem('nrcSelectedStationID'),
      nbRows: 1,
      headers: headers
    }).then(res => {
        if(realTimeData.length == 0 || realTimeData[realTimeData.length-1].x != parseInt(res.data[0].time+"000")){
          
          energy = energyactive + (res.data[0].msg.pactive * (res.data[0].time - lastEnergyTime ) / 3600)
          energyRe = energyreactive + (res.data[0].msg.preactive * (res.data[0].time - lastEnergyTime ) / 3600)
          setEnergyactive(parseFloat(energy).toFixed(6))
          setEnergyreactive(parseFloat(energyRe).toFixed(6))
          setLastEnergyTime(res.data[0].time)
          
          setCourant(res.data[0].msg.current)
          setPactive(res.data[0].msg.pactive)
          setPreactive(res.data[0].msg.preactive)
          setPapparente(res.data[0].msg.papparente)
          setVoltage(res.data[0].msg.voltage)
          setFrequence(res.data[0].msg.frequency)
          setFp(res.data[0].msg.fp)
          setRealTimeData(pre => [...pre, {
            x: parseInt(res.data[0].time+"000"),
            y: parseFloat(res.data[0].msg.pactive)
          }])
        }
      })
      .catch(error => console.error(error))
  }
  

  const getInitialData = (headers) => {
      data = []
     
      RealTimeService.getRealTimeData({
        phase: phase,
        id: localStorage.getItem('nrcSelectedStationID'),
        nbRows: 100,
        headers: headers
      })
      .then(res => {
        console.log(res)
      
        setEnergyactive(res.data[0].msg.energyactive)
        setEnergyreactive(res.data[0].msg.energyreactive)
        setCourant(res.data[0].msg.current)
        setPactive(res.data[0].msg.pactive)
        setPreactive(res.data[0].msg.preactive)
        setPapparente(res.data[0].msg.papparente)
        setVoltage(res.data[0].msg.voltage)
        setFrequence(res.data[0].msg.frequency)
        setFp(res.data[0].msg.fp)

        getLastEnergieValue(res.data)
        for (const dataObj of res.data) {
          data.unshift({
            x: parseInt(dataObj.time+"000"),
            y: parseFloat(dataObj.msg.pactive)
          })
        }



        setRealTimeData(data)
      })
      .catch(error => {
          console.error(error)
      })
  }

  const getLastEnergieValue = () => {
    let resultEnergy, resultEnergyRe, energyTime;

    DataTimeService.getDataCounterByTime({
      client: localStorage.getItem('nrcSelectedStationID'), 
      phase: phase, 
      startDate: (new Date().toISOString()).substring(0,10)+" 00:00:00", 
      endDate:   (new Date().toISOString()).substring(0,10)+" 23:59:59"
    })
    .then(res => {


          axios
          .post(urlLastAquisition, {
            client: localStorage.getItem('nrcSelectedStationID'), phase: phase, 
            startDate: Date.parse((new Date().toISOString()).substring(0,10)+" 00:00:00")/1000 +3600
          })
          .then(resLastAquisition => {

            // if(resLastAquisition.data != []){
              resultEnergy = parseFloat(resLastAquisition.data[0].msg.energyactive)
              resultEnergyRe = parseFloat(resLastAquisition.data[0].msg.energyreactive)
              energyTime = parseInt(resLastAquisition.data[0].time)
              
              if(res.data.Items[0]){

                if(DateOperations.LastAquisitionLooksNear(res.data.Items[0].time, resLastAquisition.data[0].time))
                energyTime = parseInt(resLastAquisition.data[0].time)
                else energyTime = parseInt(res.data.Items[0].time) - 3
                
                for (const dataObj of res.data.Items) {
                  
                  resultEnergy = resultEnergy + parseFloat(dataObj.msg.pactive)*((parseInt(dataObj.time) - energyTime) / 3600)
                  resultEnergyRe = resultEnergyRe + parseFloat(dataObj.msg.preactive)*((parseInt(dataObj.time) - energyTime) / 3600)
                  energyTime = parseInt(dataObj.time)
                  // console.log(resultEnergy)
                }
              }

              setLastEnergyTime(energyTime)
              setEnergyactive(resultEnergy.toFixed(6))
              setEnergyreactive(resultEnergyRe.toFixed(6))
              } 
          )
      
    })
  } 


  useEffect(() => {
    console.log('mounted');
    setRealTimeData([])
    getSession()
    .then(({headers}) => {
      getInitialData(headers)
    })
    .catch(err => {
      console.log(err)
    });
   
    return () => console.log('out_dashboard');
  }, [phase])


  const onPhaseChange = event => {
   setPhase(event.value);
   console.log(event.value)
  } 
  

  const options = [
    { value: '1', label: 'phase 1' },
    { value: '2', label: 'phase 2' },
    { value: '3', label: 'phase 3' },
    { value: '4', label: 'phase total' }
  ]

  const options2 = [
    { value: '1', label: 'Général' },
    { value: '2', label: 'Moteur' }
  
  ]     

  


  return (
    <>

      <div className="flex-wrap w-full lg:w-6/12 xl:w-4/12 px-4 mb-6 ">
       { ( localStorage.getItem('nrcSelectedStationID') == 9) ?
         <Select options={options2} defaultValue={options2[0]} onChange={onPhaseChange}/>
         :
         <Select options={options} defaultValue={options[0]} onChange={onPhaseChange}/>
       } 
       {/* <h2>{localStorage.getItem('name')}</h2> */}
      </div>

      
     

      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        
           <ChartRealTime Phase={phase} Data={realTimeData}/>
        </div>
        <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
          <ChartFreq 
            Val={voltage}
            Max="500"
            Label="Tension (V)"
          />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          
           <MultiProgressData
            Courant={courant}
            Energyactive={energyactive}
            Energyreactive={energyreactive}
            Pactive={pactive}
            Preactive={preactive}
            Papparente={papparente}
           />
        </div>
        <div className="w-full xl:w-4/12 px-4">
        <ChartFreq 
            Val={frequence}
            Max="70"
            Label="Fréquence (Hz)"
          />
        </div>
        <div className="w-full xl:w-4/12 px-4">
        <ChartFreq 
            Val={fp}
            Max="50"
            Label="Facteur de puissance"

          />
        </div>
      </div>
      <div className="flex flex-wrap mt-4">
       
        
      </div>
    </>
  );
}
