import  React, { useEffect, useState } from "react";
import { Table } from 'antd';
import { DatePicker, Select } from 'antd';
import { ClapSpinner  } from "react-spinners-kit"
import ReactExport from "react-export-excel";
import moment from "moment";
import axios from 'axios';
import { columns } from "utils/constants/tableColumns";
import DateOperations from "utils/helpers/dateOperations";


const { Option } = Select;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
let dataTable = []

export default function CardTableElement(props) {
    const [phase, setPhase] = useState("1")
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [dataCSV, setDataCSV] = useState([])
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState({})
    const [lastEnergyT, setLastEnergyT] = useState(1634134191)
    const [loadingExcel, setLoadingExcel] = useState(false);

    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 20,
      total: 20
    })
    const urlChart = process.env.REACT_APP_GET_DATA_BY_TIME_PAGINATION
    const urlChart2 = process.env.REACT_APP_GET_DATA_BY_TIME
    const urlLastAquisition = process.env.REACT_APP_GET_LAST_AQUISITION

    const getDataForExcel = (tt) => {    
      
      setLoadingExcel(true)
      let dataExcel = [];
      let resultEnergy, resultEnergyRe, energyTime;

      axios
        .post(urlChart2, {
          client: localStorage.getItem('nrcSelectedStationID'), phase: phase, 
          startDate: (startDate.toISOString()).substring(0,10)+" 00:00:00", 
          endDate:   (endDate.toISOString()).substring(0,10)+" 23:59:59"
        })
        .then(res => {
          energyTime = tt
          for (const dataObj of res.data.Items) {

            resultEnergy = parseFloat(dataObj.msg.pactive)*(parseInt(dataObj.time) - energyTime) / 3600
            resultEnergyRe = parseFloat(dataObj.msg.preactive)*(parseInt(dataObj.time) - energyTime) / 3600

            dataExcel.push({
              Time: new Date(parseInt(dataObj.time+"000")-3600000).toLocaleString("fr-FR"),
              Frequency: parseFloat(dataObj.msg.frequency),
              Voltage: parseFloat(dataObj.msg.voltage),
              Current: parseFloat(dataObj.msg.current),
              Pactive: parseFloat(dataObj.msg.pactive),
              Preactive: parseFloat(dataObj.msg.preactive),
              Fp: parseFloat(dataObj.msg.fp),
              EnergyActive: resultEnergy,
              EnergyReactive: resultEnergyRe
            });
            energyTime = parseInt(dataObj.time)

          }
          setDataCSV(dataExcel)
          console.log(dataExcel)

          setLoadingExcel(false)
        })
    }


    const handleTableChange = (pagination, filters, sorter)  => {
        fetch({
          sortField: sorter.field,
          sortOrder: sorter.order,
          pagination,
          ...filters,
        });
    };
    

    const fetch = (params = {}) => {
      setData([])
      setDataCSV([])
      let resultEnergy, resultEnergyRe, energyTime;
      setLoading(true) 
      axios
      .post(urlChart, {
        client: localStorage.getItem('nrcSelectedStationID'), phase: phase, 
        startDate: (startDate.toISOString()).substring(0,10)+" 00:00:00", 
        endDate:   (endDate.toISOString()).substring(0,10)+" 23:59:59",
        pageSize: params.pagination.pageSize,
        pagePointer: params.pagination.current,
        pageTotal: params.pagination.total,
        lastEvaluatedKey: lastEvaluatedKey
      })
      .then(res => {
        if(res.data.Count != []){
          setPagination({
            ...params.pagination,
            total: res.data.Count,
          })
        }
        dataTable = []

        if(res.data.Data.Items[0]){

          axios
          .post(urlLastAquisition, {
            client: localStorage.getItem('nrcSelectedStationID'), phase: phase, 
            startDate: parseInt(res.data.Data.Items[0].time), 
          })
          .then(resLastAquisition => {

            console.log(DateOperations.LastAquisitionLooksNear)
            if(DateOperations.LastAquisitionLooksNear(res.data.Data.Items[0].time, resLastAquisition.data[0].time)){
              console.log("----------------- looks near !");
              energyTime = parseInt(resLastAquisition.data[0].time)
            }
            else energyTime = parseInt(res.data.Data.Items[0].time) - 3
            
            getDataForExcel(energyTime)

            for (const dataObj of res.data.Data.Items) {
              resultEnergy = parseFloat(dataObj.msg.pactive)*(parseInt(dataObj.time) - energyTime) / 3600
              resultEnergyRe = parseFloat(dataObj.msg.preactive)*(parseInt(dataObj.time) - energyTime) / 3600
              dataTable.push({
                Time: parseInt(dataObj.time+"000")-3600000,
                Frequency: parseFloat(dataObj.msg.frequency),
                Voltage: parseFloat(dataObj.msg.voltage),
                Current: parseFloat(dataObj.msg.current),
                Pactive: parseFloat(dataObj.msg.pactive),
                Preactive: parseFloat(dataObj.msg.preactive),
                Fp: parseFloat(dataObj.msg.fp),
                EnergyActive: resultEnergy.toFixed(10),
                EnergyReactive: resultEnergyRe.toFixed(10), 
              });
              energyTime = parseInt(dataObj.time)
              
            }
            setLastEnergyT(energyTime)
            setData(dataTable)

            if(res.data.Data.LastEvaluatedKey != null)
            setLastEvaluatedKey(res.data.Data.LastEvaluatedKey)
            setLoading(false)
            
          })
          .catch(err => {
            energyTime = parseInt(res.data.Data.Items[0].time) - 3
            getDataForExcel(energyTime)
            
            for (const dataObj of res.data.Data.Items) {
              resultEnergy = parseFloat(dataObj.msg.pactive)*(parseInt(dataObj.time) - energyTime) / 3600
              resultEnergyRe = parseFloat(dataObj.msg.preactive)*(parseInt(dataObj.time) - energyTime) / 3600
              
              dataTable.push({
                Time: parseInt(dataObj.time+"000")-3600000,
                Frequency: parseFloat(dataObj.msg.frequency),
                Voltage: parseFloat(dataObj.msg.voltage),
                Current: parseFloat(dataObj.msg.current),
                Pactive: parseFloat(dataObj.msg.pactive),
                Preactive: parseFloat(dataObj.msg.preactive),
                Fp: parseFloat(dataObj.msg.fp),
                EnergyActive: resultEnergy.toFixed(10),
                EnergyReactive: resultEnergyRe.toFixed(10), 
              });
              energyTime = parseInt(dataObj.time)
              
            }
            setLastEnergyT(energyTime)
            setData(dataTable)
            if(res.data.Data.LastEvaluatedKey != null)  // Need Check
              setLastEvaluatedKey(res.data.Data.LastEvaluatedKey)
            setLoading(false)
            console.log(data);
          }) 
        } else {
          setLoading(false) 
        }
      }) 
    };

    const onPhaseChange = value => {
      setPhase(value);
    } 
  
    const onTimeStartChange = (date, dateString) => {
      setStartDate(date)
    } 

    const onTimeEndChange = (date, dateString) => {
      setEndDate(date)
    }
  
    const onScan = () => {
      fetch({pagination})
      console.log('get')
    }

    useEffect(() => {
      fetch({pagination});
    }, [phase]);
    
  return (
    <>
    <div className="flex w-full mb-12">
      <DatePicker
        defaultValue={moment()}
        format="DD-MM-YYYY"
        onChange={onTimeStartChange}
      />
      <DatePicker
        defaultValue={moment()}
        format="DD-MM-YYYY"
        onChange={onTimeEndChange}
      />
        
      <Select style={{height: "46px"}}  defaultValue="1" onChange={onPhaseChange} style={{ width: 200 }}>
      { ( localStorage.getItem('nrcSelectedStationID') != 9) ?
            (<><Option key="1">Phase 1</Option>
              <Option key="2">Phase 2</Option>
              <Option key="3">Phase 3</Option></>)
            :
            (<><Option key="1">Général</Option>
              <Option key="2">Moteur</Option></>)
          }  
      </Select>
      
      <button onClick={() => onScan()} className="text-lightBlue-500 bg-transparent border border-solid border-lightBlue-500 hover:bg-lightBlue-500 hover:text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none">
        Scan
      </button>
      {(dataCSV != [] ) &&  <ExcelFile filename={(startDate.toISOString()).substring(0,10)+"_"+
      (endDate.toISOString()).substring(0,10)} 
      element={ 
         
          loadingExcel ? <div className="p-2" > <ClapSpinner  size={30} color="#0EA5E9" loading={loadingExcel} /> </div>
            : 
            <button onClick={ () => console.log(dataCSV)} 
            className="text-emerald-500 bg-transparent border border-solid border-lightBlue-500 hover:bg-lightBlue-500 hover:text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none"
            >Exporter Excel</button>
      }
      >
           
                 <ExcelSheet data={dataCSV} name="NRCounter">
                    <ExcelColumn label="Time" value="Time"/>
                    <ExcelColumn label="Frequency" value="Frequency"/>
                    <ExcelColumn label="Voltage" value="Voltage"/>
                    <ExcelColumn label="Current" value="Current"/>
                    <ExcelColumn label="Pactive" value="Pactive"/>
                    <ExcelColumn label="Preactive" value="Preactive"/>
                    <ExcelColumn label="Fp" value="Fp"/>
                    <ExcelColumn label="EnergyActive" value="EnergyActive"/>
                    <ExcelColumn label="EnergyReactive" value="EnergyReactive"/>
                </ExcelSheet>
                
    </ExcelFile>
    }
    </div>
    <div className="flex" >       
    <Table className="w-full"
        columns={columns}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 1000 }}
      />
    </div>
    
    </>
  );
}
